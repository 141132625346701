import { ChecklistItemIndividual } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function checklistItem__client__addIndividualChecklistItem(p: {
  checklistItem: Omit<ChecklistItemIndividual, "id" | "createdAtMS">;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const id = h.ChecklistItem.generateId();
  await h.ChecklistItem.add({ doc: { ...p.checklistItem, ...{ createdAtMS: Date.now(), id } } });
}

// i18n certified - complete
