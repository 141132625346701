import { TeamId, PlayerId, ChecklistItemId, ChecklistItemStatus, ChecklistItemStatusDetails } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function checklistItem__client__updateTeamItemStatusDetails(p: {
  teamId: TeamId;
  checklistItemId: ChecklistItemId;
  statusDetails: ChecklistItemStatusDetails;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  let newStatusDetails = p.statusDetails;
  if (newStatusDetails.status === undefined) {
    newStatusDetails.status = h._MagicDeleteValue;
  }
  if (newStatusDetails.notes === "" || undefined) {
    newStatusDetails.notes = h._MagicDeleteValue;
  }

  await h.ChecklistItem.update({ id: p.checklistItemId, doc: { statusDetails: newStatusDetails } });
}

// i18n certified - complete
