import { TeamId, PlayerId, ChecklistItemId, ChecklistItemStatusDetails } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function checklistItem__client__updatePlayerStatusDetails(p: {
  teamId: TeamId;
  checklistItemId: ChecklistItemId;
  playerId: PlayerId;
  statusDetails: ChecklistItemStatusDetails;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  let newStatusDetails = p.statusDetails;
  if (newStatusDetails.status === undefined) {
    newStatusDetails.status = h._MagicDeleteValue;
  }
  if (newStatusDetails.notes === "" || undefined) {
    newStatusDetails.notes = h._MagicDeleteValue;
  }

  await h.ChecklistItem.update({ id: p.checklistItemId, doc: { playerStatusDetails: { [p.playerId]: newStatusDetails } } });
}

// i18n certified - complete
