import { TeamId, ChecklistItem } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { createBifrostSubscription } from "@ollie-sports/react-bifrost";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function checklistItem__client__getChecklistItemsSubscriptionForTeam(p: { teamId: TeamId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftQuerySubToBifrostSub(
    h.ChecklistItem.querySubscription({ where: [{ teamId: ["==", p.teamId] }, { deletedAtMS: ["==", 0] }] })
  );
}

// i18n certified - complete
