import { TeamId, ChecklistItem } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function checklistItem__client__getChecklistItemsForTeam(p: { teamId: TeamId }): Promise<ChecklistItem[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const checklistItems = await h.ChecklistItem.query({ where: [{ teamId: ["==", p.teamId] }, { deletedAtMS: ["==", 0] }] });
  return checklistItems.docs;
}

// i18n certified - complete
