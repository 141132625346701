import {
  TeamId,
  PlayerId,
  ChecklistItemId,
  ChecklistItemStatus,
  ChecklistItem,
  ChecklistItemIndividual
} from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function checklistItem__client__deleteChecklistItem(p: { checklistItemId: ChecklistItemId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.ChecklistItem.update({ id: p.checklistItemId, doc: { deletedAtMS: Date.now() } });
}

// i18n certified - complete
